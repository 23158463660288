/* eslint-disable @next/next/no-img-element */
import React, { useEffect } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { useRouter } from "next/router";
import {
  HomeButtonContainer,
  PageContainer,
  TextContainer,
} from "styles/Unauthorized";

import { theme } from "@bxtech/bx-ui-lib";
import Button from "@bxtech/bx-ui-lib/elements/Button";

import { trackPageView } from "@/utils/analytics/mixpanel";

const PageNotFound = () => {
  const router = useRouter();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    trackPageView("Page Not Found");
  }, []);

  return (
    <PageContainer>
      <TextContainer>
        <Box sx={{ width: "100%", maxWidth: "400px", height: "auto" }}>
          <img
            src={`${process.env.NEXT_PUBLIC_BX_CDN_URL}/404.svg`}
            alt="404"
            style={{ width: "100%", height: "auto" }}
          />
        </Box>
        <Typography
          variant={isSmallScreen ? "h4" : "h3"}
          color="text.secondary"
          align="center"
          style={{
            marginTop: theme.space(50),
            maxWidth: "547px",
            padding: `0 ${theme.space(40)}`,
            opacity: 0.8,
          }}
        >
          We can&apos;t find the page you&apos;re looking for.
        </Typography>
        <HomeButtonContainer>
          <Button
            label="Return home"
            onClick={() => router.push("/")}
            variant="contained"
            sx={{ mr: 2 }}
          />
        </HomeButtonContainer>
      </TextContainer>
    </PageContainer>
  );
};

export default PageNotFound;
