import { Box } from "@mui/material";
import styled from "styled-components";

export const PageContainer = styled(Box)`
  background-image: url("${process.env
    .NEXT_PUBLIC_BX_CDN_URL}/authBackground.jpg");
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const TextContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.space(40)};
  border-radius: ${({ theme }) => theme.space(80)};
  max-width: 800px;
`;

export const HomeButtonContainer = styled(Box)`
  margin-top: ${({ theme }) => theme.space(100)};
  display: flex;
  gap: ${({ theme }) => theme.space(20)};
`;
